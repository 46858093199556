/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Hero from '../page-components/Consultants/Hero';
import QuotationRequest from '../page-components/QuotationRequest';
import OurConsultants from '../page-components/Consultants';

import schema from '../shared/FormSchemas/consultantSchema.json';
import { useCountries, useDomains } from '../shared/Hooks/useData';
import { API_CONSULTANT_FORM } from '../shared/ApIs';
// Style

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Consultants() {
  /* ********************************** HOOKS ********************************* */
  const { language } = useI18next();
  const { countries } = useCountries();
  const { domains } = useDomains();

  const selectData = useMemo(() => {
    return {
      country: countries.map(({ attributes, id }) => ({
        label: attributes[`name_${language}`],
        value: id,
      })),
      domain: domains.map(({ attributes }) => ({
        label: attributes?.name,
        value: attributes?.name,
      })),
      domainValues: domains.map(({ id, attributes }) => ({
        label: attributes?.name,
        value: id,
      })),
    };
  }, [countries, domains, language]);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  // Localization
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(API_CONSULTANT_FORM, {
        data: {
          ...data,
          country: countries.find(
            ({ attributes }) => attributes?.name === data?.country,
          )?.id,
          domain: countries.find(
            ({ attributes }) => attributes?.name === data?.country,
          )?.id,
        },
      });
      setResponse('SUCCESS');
    } catch (error) {
      setResponse('ERROR');
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setResponse();
    }, 3000);
  }, [response]);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <Hero />
      <OurConsultants domains={selectData?.domainValues} />
      <QuotationRequest
        schema={schema}
        onSubmit={onSubmit}
        loading={loading}
        response={response}
        selectData={selectData}
      />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Consultants", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Consultants;
