/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

// Assets
import ArrowDown from '../../../images/selectProjectCountries/arrow-down.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function DefaultSelect({
  inputId,
  placeholder,
  options,
  name,
  height,
  value,
  onChange,
}) {
  /* ******************************** HOOKS ******************************* */
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);

  const selectedLabel = options?.find(
    ({ value: optionValue }) => optionValue === value,
  )?.label;

  /* ******************************** CALLBACKS ******************************* */

  function toggleOptions() {
    setIsOptionsOpen(!isOptionsOpen);
  }

  const handleKeyDown = (index) => (e) => {
    if (e.key === ' ' || e.key === 'SpaceBar' || e.key === 'Enter') {
      e.preventDefault();
      setSelectedOption(index);

      onChange(options[index]?.value);
      setIsOptionsOpen(false);
    }
  };

  function handleBlur(e) {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOptionsOpen(false);
    }
  }

  /* ******************************** VARIABLES ******************************* */
  let buttonClassName = '';
  if (selectedOption === -1) {
    buttonClassName += 'default';
  }
  if (isOptionsOpen) {
    buttonClassName += ' expanded';
  }

  useEffect(() => {}, [selectedOption]);

  return (
    <div className="custom-select" onBlur={handleBlur}>
      <div className="native-select">
        <select name={name} id={inputId} value={options[selectedOption]?.value}>
          <option value={options[selectedOption]?.value}>
            {selectedLabel ?? placeholder}
          </option>
          {options?.map((option, index) => (
            <option
              key={option?.value}
              value={options[index]?.value}
              selected={index === selectedOption}
            >
              {option?.label}
            </option>
          ))}
        </select>
      </div>
      <div className="wrapper">
        <div
          className="container"
          style={height !== undefined ? { height: `${height}px` } : {}}
          onBlur={handleBlur}
        >
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            aria-labelledby={inputId}
            className={buttonClassName}
            onClick={toggleOptions}
          >
            {selectedOption < 0 ? placeholder : options[selectedOption]?.label}
            <span className={`arrow ${isOptionsOpen ? 'rotate' : ''}`}>
              <img src={ArrowDown} alt="" aria-hidden="true" />
            </span>
          </button>
          <div className={`options-container ${isOptionsOpen ? 'show' : ''}`}>
            <ul className="options" tabIndex={-1}>
              {options.map(
                ({ label: optionLabel, value: optionValue }, index) => (
                  <li
                    id={optionValue}
                    key={optionValue}
                    role="option"
                    aria-selected={selectedOption === index}
                    tabIndex={0}
                    onKeyDown={handleKeyDown(index)}
                    onClick={() => {
                      if (selectedOption === index) {
                        setSelectedOption(-1);
                        setIsOptionsOpen(false);
                        onChange(-1);
                      } else {
                        setSelectedOption(index);
                        setIsOptionsOpen(false);
                        onChange(options[index]?.value);
                      }
                    }}
                  >
                    {optionLabel}
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultSelect.propTypes = {
  name: PropTypes.string,
  height: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

DefaultSelect.defaultProps = {
  name: '',
  height: undefined,
  value: -1,
};

export default DefaultSelect;
