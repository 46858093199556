/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import axios from 'axios';
import { API_CONSULTANTS, API_UPLOADS } from '../../shared/ApIs';
import DefaultSelect from '../../shared/UIKit/DefaultSelect';
import Spinner from '../../shared/UIKit/Spinner';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function OurConsultants({ domains }) {
  /* ******************************** HOOKS ******************************* */
  const [consultants, setConsultants] = useState([]);
  const [speciality, setSpeciality] = useState(-1);
  const [loading, setLoading] = useState(false);
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const { title, filterPlaceholder } = t('consultants', {
    returnObjects: true,
  });

  useEffect(() => {
    (async function getConsultants() {
      try {
        setLoading(true);
        // eslint-disable-next-line operator-linebreak
        const filters =
          speciality !== -1 ? `&filters[domain][id][$eq]=${speciality}` : '';
        const { data } = await axios.get(API_CONSULTANTS + filters);
        setConsultants(data?.data);
      } catch {}
      setLoading(false);
    })();
  }, [speciality]);

  function List() {
    return (
      consultants?.map(({ id, attributes }) => (
        <Col
          xl={4}
          xxl={4}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          className="our-experts-contain"
          key={id}
          align="center"
          justify="center"
        >
          <div className="our-experts-contain-img">
            <img
              src={API_UPLOADS(attributes?.image?.data?.attributes?.url)}
              alt="Daf time share"
            />
            <div className="our-experts-contain-desc">
              <h2 className="our-experts-contain-desc-title">
                {attributes?.fullName}
              </h2>
              <p className="our-experts-contain-desc-description">
                {attributes?.description}
              </p>
            </div>
          </div>
        </Col>
      )) ?? null
    );
  }
  return (
    <Container className="our-experts">
      <h1 className="our-experts-title" align="center">
        {title}
      </h1>
      <div className="max-w-450 mb-30">
        <DefaultSelect
          inputId="domain"
          placeholder={filterPlaceholder}
          options={domains}
          name="domain"
          value={speciality}
          onChange={setSpeciality}
        />
      </div>
      <Row justify="center">
        {loading ? (
          <div className="loading-container">
            <div>
              <Spinner />
            </div>
          </div>
        ) : (
          <List />
        )}
      </Row>
    </Container>
  );
}

OurConsultants.propTypes = {
  domains: PropTypes.shape,
};

OurConsultants.defaultProps = {
  domains: [],
};

export default OurConsultants;
